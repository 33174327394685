import React, {useState} from 'react';
import firebase from './firebase';
import {Container, Row, Col, Button, Badge} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SweetAlert from 'react-bootstrap-sweetalert';
const shortid = require('shortid');

var startTime = new Date();

function storeData(email){
  var db = firebase.firestore();
  var endTime = new Date();
  db.collection("leads").doc(shortid.generate()).set({
    email: email,
    dateTime: endTime.getTime(),
    dateTimeString: endTime.toString(),
    elapsedTime: endTime - startTime
  })
  .then(function() {
      console.log("Document successfully written!");
  })
  .catch(function(error) {
      console.error("Error writing document: ", error);
  });
}

function isAValidEmail(email){
  if (email !== ''){
    return (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email))
  }
  else {
    return false;
  }
}

function App() {
  const [emailAlert,setEmailAlert] = useState(false)
  const [successAlert,setSuccessAlert] = useState(false)
  const [email,setEmail] = useState('')
  return (
    <>
      <Row className="m-0 p-0" style={{backgroundColor:'#da522f'}}>
        <Col sm='auto'>
          <img width="150px" src="https://3xwa2438796x1hj4o4m8vrk1-wpengine.netdna-ssl.com/wp-content/uploads/2016/06/producthuntcat.png"></img>
        </Col>
        <Col className="my-auto">
          <Row>
            <Col className="h3">
              Hello Product Hunters!
            </Col>
          </Row>
          <Row>
            <Col className="h5">
              Join Calendbook with an exclusive offer for the Product Hunt Community.
            </Col>
          </Row>
        </Col>
      </Row>
      <Container className="App my-5">
        <Row>
          <Col className="text-center h1">
            Calendbook - Share your calendar with a link
          </Col>
        </Row>
        <Row>
          <Col className="text-center h2">Let anyone book you in just 3 clicks</Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className="my-2 my-md-5"> 
            <Row>
              <Col className="text-center">
                <a href="https://www.calendbook.com/login">
                  <Button className="font-weight-bold">14-DAY FREE TRIAL</Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="text-center" style={{fontSize:'12px'}}>
                NO CREDIT CARD REQUIRED!
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} className="my-2 my-md-5">
            <Row>
              <Col className="text-center">
                <Button className="font-weight-bold" variant="warning" onClick={() => setEmailAlert(true)}>GET 50% OFF COUPON</Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-center" style={{fontSize:'12px'}}>
                GET PREMIUM FOR <del>€36</del> €18!
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <iframe 
              title="product-hunt-iframe" 
              id="product-hunt-iframe" 
              style={{border: 'none'}} 
              src="https://cards.producthunt.com/cards/posts/277249?v=1"
              width="360" 
              height="405" 
              scrolling="no" 
              allowfullscreen>
            </iframe>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            Made with
            <span aria-label="love" role="img" style={{color:'red'}}> &#x2764; </span>
            in Puglia, Southern Italy.
          </Col>
        </Row>
      </Container>

      {emailAlert &&

        <SweetAlert
        title={"What's your email?"}
        onConfirm={()=>{
          if(isAValidEmail(email)){
            storeData(email);
            setEmailAlert(false);
            setSuccessAlert(true)};
          }
        }
        onCancel={()=>setEmailAlert(false)}
        type={'controlled'}
        style={{color: 'black' }}
        >
        {(renderProps: SweetAlertRenderProps) => (
          <form>
          <input
            type='email'
            className="form-control"
            value={email}
            onKeyDown={renderProps.onEnterKeyDownConfirm}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={'Type here your email...'}
          />
          </form>
        )}
        </SweetAlert>
      }

      {successAlert &&
        <SweetAlert
          success title="Whoo-ho!"
          onConfirm={() => window.open('https://www.calendbook.com/login','_blank')}
          onCancel={()=>console.log('')}
          confirmBtnText="Sign in to Calendbook"
          style={{color: 'black' }}
        >
          You just won a promo code:
          <h3>
            <Badge variant="secondary">PH50OFF</Badge>
          </h3>
          Go grab your premium plan!
        </SweetAlert>
      }

    </>
  );
}

export default App;
