import firestore from 'firebase/firestore'
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

const settings = {timestampsInSnapshots: false};
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBMzviTUJYSuXlDF4TbsfGQn1IwRPr1eAk",
  authDomain: "ph-landing.firebaseapp.com",
  projectId: "ph-landing",
  storageBucket: "ph-landing.appspot.com",
  messagingSenderId: "12389872193",
  appId: "1:12389872193:web:b93888e408a505c7ddccc0",
  measurementId: "G-BLHQLR3E9W"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings(settings);

export default firebase;
